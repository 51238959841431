import React from "react";
import "../styles/About.css";
import image from "../images/profile.jpeg";
import { useInView } from "react-intersection-observer";

const About = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Trigger the animation only once
    threshold: 0.1,
  });

  return (
    <div>
      <div className={`about ${inView ? "about-visible" : ""}`} ref={ref}>
        <div className="container-about">
          <div className="item1">
            <div className="myimg">
              <img src={image} alt="ME!" />
            </div>
          </div>
          <div className="item2">
            <h1>1 - About Me</h1>
            <p>
              Hi! My name is Ryan Hu and I&#39;m a computer science student at
              the University of Waterloo (2021 - 2026).
            </p>
            <p>
              My passion for software development ignited during my first high
              school computer science course, where I discovered technology's
              transformative potential. Through various internships and
              projects, I've gained extensive experience in frontend development
              with React and modern web technologies. I'm currently expanding my
              expertise into backend development and system architecture as a
              Software Developer Intern at Workleap. In my free time, I enjoy
              playing basketball, hiking, and learning new programming
              languages.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
